import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Enfoque 1: Diccionario de patrones de traducción
// Esto permite traducciones más flexibles basadas en patrones comunes
const routePatterns = {
  es: {
    // Patrones para nombres de rutas
    "Inca Trail": "Camino Inca",
    "Short Inca Trail": "Camino Inca Corto",
    "Days": "Días",
    "Day": "Día",
    "Machu Picchu": "Machu Picchu",
    "Ausangate": "Ausangate", // Se mantiene igual en español
    "Choquequirao": "Choquequirao", // Se mantiene igual en español
    "&": "y"
  },
  fr: {
    "Inca Trail": "Chemin de l'Inca",
    "Short Inca Trail": "Chemin de l'Inca Court",
    "Days": "Jours",
    "Day": "Jour",
    "Machu Picchu": "Machu Picchu",
    "Ausangate": "Ausangate", // Se mantiene igual en francés
    "Choquequirao": "Choquequirao", // Se mantiene igual en francés
    "&": "et"
  }
};

// Traducciones de meses
const monthTranslations = {
  en: {
    "January": "January", "February": "February", "March": "March",
    "April": "April", "May": "May", "June": "June",
    "July": "July", "August": "August", "September": "September",
    "October": "October", "November": "November", "December": "December",

  },
  es: {
    "January": "Enero", "February": "Febrero", "March": "Marzo",
    "April": "Abril", "May": "Mayo", "June": "Junio",
    "July": "Julio", "August": "Agosto", "September": "Septiembre",
    "October": "Octubre", "November": "Noviembre", "December": "Diciembre",

  },
  fr: {
    "January": "Janvier", "February": "Février", "March": "Mars",
    "April": "Avril", "May": "Mai", "June": "Juin",
    "July": "Juillet", "August": "Août", "September": "Septembre",
    "October": "Octobre", "November": "Novembre", "December": "Décembre",

  }
};

// Traducciones de textos UI
const translations = {
  en: {
    title: "CONFIRMED DEPARTURES 2025",
    description: "Join us on an adventure! These are the next trekking trips already confirmed. Reserve your spot and enjoy an unforgettable experience in nature.",
    noDepartures: "No scheduled departures",
    tableHeaders: {
      number: "Nro",
      route: "Trek",
      date: "Departure Date"
    }
  },
  es: {
    title: "SALIDAS CONFIRMADAS 2025",
    description: "¡Acompáñanos en una aventura! Estas son las próximas salidas de trekking ya confirmadas. Reserva tu lugar y disfruta de una experiencia inolvidable en la naturaleza.",
    noDepartures: "No hay salidas programadas",
    tableHeaders: {
      number: "Nro",
      route: "Trek",
      date: "Fecha de Salida"
    }
  },
  fr: {
    title: "DÉPARTS CONFIRMÉS 2025",
    description: "Rejoignez-nous pour une aventure ! Voici les prochains départs de trekking déjà confirmés. Réservez votre place et profitez d'une expérience inoubliable dans la nature.",
    noDepartures: "Aucun départ prévu",
    tableHeaders: {
      number: "N°",
      route: "Randonnée",
      date: "Date de départ"
    }
  }
};

// Detectar idioma basado en dominio o ruta
const getLanguage = () => {
  const host = window.location.hostname;
  const path = window.location.pathname;
  
  if (host.includes('.es') || host.includes('es.')) return 'es';
  if (host.includes('.fr') || host.includes('fr.')) return 'fr';
  if (path.includes('/es')) return 'es';
  if (path.includes('/fr')) return 'fr';
  
  return 'en';
};

// Función para traducir solo los nombres de los meses en las fechas
const translateMonthInDate = (dateStr, language) => {
  if (!dateStr) return '';
  
  // Si estamos en inglés, devolver la fecha original
  if (language === 'en') return dateStr;
  
  const months = monthTranslations[language];
  
  // Reemplazar nombres de meses completos
  let translatedDate = dateStr;
  
  // Primero intentar con nombres completos (January, February, etc.)
  Object.keys(months).forEach(englishMonth => {
    // Usar regex para encontrar y reemplazar solo palabras completas
    const regex = new RegExp(`\\b${englishMonth}\\b`, 'g');
    if (regex.test(translatedDate)) {
      translatedDate = translatedDate.replace(regex, months[englishMonth]);
    }
  });
  
  return translatedDate;
};

// Función para traducir títulos de meses
const translateMonth = (monthName, language) => {
  if (!monthName) return '';
  
  // Si estamos en inglés, devolver el mes original
  if (language === 'en') return monthName;
  
  const months = monthTranslations[language];
  
  // Buscar la traducción del mes
  return months[monthName] || monthName;
};

// Componente TableSkeleton
const TableSkeleton = () => {
  return (
    <div className="animate-pulse">
      <div className="h-8 bg-gray-200 rounded mb-1"></div>
      <table className="min-w-full border border-gray-200 bg-white">
        <thead className="bg-gray-50">
          <tr className="border-b bg-[#008b39]">
            <th colSpan="3" className="uppercase">
              <div className="h-6 bg-[#006b2d] rounded w-1/4 mx-auto"></div>
            </th>
          </tr>
          <tr>
            <th className="border-b px-4 py-2 text-left">
              <div className="h-4 bg-gray-300 rounded w-3/4"></div>
            </th>
            <th className="border-b px-4 py-2 text-left">
              <div className="h-4 bg-gray-300 rounded w-3/4"></div>
            </th>
            <th className="border-b px-4 py-2 text-left">
              <div className="h-4 bg-gray-300 rounded w-3/4"></div>
            </th>
          </tr>
        </thead>
        <tbody>
          {[1, 2].map((row) => (
            <tr key={`skeleton-row-${row}`}>
              <td className="whitespace-nowrap px-4 py-3">
                <div className="h-4 bg-gray-200 rounded w-full"></div>
              </td>
              <td className="whitespace-nowrap px-4 py-3">
                <div className="h-4 bg-gray-200 rounded w-full"></div>
              </td>
              <td className="whitespace-nowrap px-4 py-3">
                <div className="h-4 bg-gray-200 rounded w-full"></div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const App = () => {
  const [sheetsData, setSheetsData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sheetNames, setSheetNames] = useState([]);
  const [routeTranslations, setRouteTranslations] = useState({});
  
  const language = getLanguage();
  const t = translations[language];

  const API_KEY = 'AIzaSyALZVsUEiIkhlw5B18V_0tVCqkwl5ZXHcE';
  const SPREADSHEET_ID = '14uIXJhonL4LL6efA2ohUV-3SqgKlDsDDMplGpZ0U7rw';
  // ID de la hoja que contiene traducciones (opcional para enfoque 2)
  const TRANSLATIONS_SHEET_ID = 'Translations'; 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const metadataResponse = await axios.get(
          `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}?key=${API_KEY}`
        );
        
        const allSheetNames = metadataResponse.data.sheets
          .map(sheet => sheet.properties.title)
          .filter(name => name !== TRANSLATIONS_SHEET_ID); // Filtrar la hoja de traducciones si existe
        
        setSheetNames(allSheetNames);

        // Enfoque 2: Obtener traducciones de una hoja específica (opcional)
        try {
          const translationsResponse = await axios.get(
            `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/${TRANSLATIONS_SHEET_ID}?key=${API_KEY}`
          );
          
          if (translationsResponse.data.values && translationsResponse.data.values.length > 0) {
            // Procesar hoja de traducciones
            // Formato esperado: [ruta_en, ruta_es, ruta_fr]
            const translations = {};
            
            translationsResponse.data.values.forEach(row => {
              if (row.length >= 3) {
                const [en, es, fr] = row;
                if (en) {
                  translations[en] = {
                    es: es || en,
                    fr: fr || en
                  };
                }
              }
            });
            
            setRouteTranslations(translations);
          }
        } catch (error) {
          console.log('No se encontró hoja de traducciones o hubo un error:', error);
          // Continuar con el resto del código
        }

        const data = {};
        
        for (const sheetName of allSheetNames) {
          const response = await axios.get(
            `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/${sheetName}?key=${API_KEY}`
          );
          
          if (response.data.values && response.data.values.length > 1) {
            const headers = response.data.values[1];
            const rows = response.data.values.slice(2);
            
            data[sheetName] = {
              rows: rows.map((row, index) => ({
                id: `${sheetName}-row-${index}`,
                nro: index + 1,
                ruta: row[0] || '',
                fecha: row[1] || ''
              }))
            };
          }
        }
        
        setSheetsData(data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
        console.error('Error fetching data:', err);
      }
    };

    fetchData();
  }, []);

  // Función de traducción de rutas dinámica
  const translateRoute = (routeName) => {
    if (!routeName) return t.noDepartures;
    
    // Si estamos en inglés, devolver la ruta original
    if (language === 'en') return routeName;
    
    // Enfoque 2: Usar traducciones de la hoja (si están disponibles)
    if (Object.keys(routeTranslations).length > 0 && routeTranslations[routeName]) {
      return routeTranslations[routeName][language] || routeName;
    }
    
    // Enfoque 1: Usar patrones para traducir partes de la ruta
    let translatedRoute = routeName;
    
    // Aplicar traducciones de patrones específicos primero (como "Short Inca Trail")
    Object.keys(routePatterns[language]).forEach(pattern => {
      // Usamos regex para encontrar patrones completos de palabras
      const regex = new RegExp(`\\b${pattern}\\b`, 'g');
      translatedRoute = translatedRoute.replace(regex, routePatterns[language][pattern]);
    });
    
    // Traducción de números seguidos de "Days"
    translatedRoute = translatedRoute.replace(/(\d+)\s+Days/gi, function(match, number) {
      if (language === 'es') return `${number} Días`;
      if (language === 'fr') return `${number} Jours`;
      return match;
    });
    
    return translatedRoute;
  };

  if (error) return (
    <section>
      <div className="mx-auto ">
        <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4">
          <p className="font-bold">Error</p>
          <p>{error}</p>
          <p className="mt-2 text-sm">Please check your connection and try again.</p>
        </div>
      </div>
    </section>
  );

  return (
    <section>
      <div className="mx-auto ">
        <div className="mb-1 xl:mb-6">
          <span className="text-center block text-xl xl:text-3xl font-bold text-red-500 px-4 xl:px-0">{t.title}</span>
        </div>

        <div className="mb-5 px-3 hidden md:hidden xl:block">
          <p className="text-gray-700">{t.description}</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-1 xl:gap-5">
          {loading ? (
            <>
              {[1, 2, 3, 4].map((item) => (
                <div key={`skeleton-table-${item}`} className="overflow-x-auto">
                  <TableSkeleton />
                </div>
              ))}
            </>
          ) : (
            sheetNames.map((sheetName) => (
              <div key={`table-${sheetName}`} className="overflow-x-auto">
                <table className="min-w-full border border-gray-200 bg-white">
                  <thead>
                    <tr className="border-b ">
                      <th colSpan="3" className="uppercase text-white font-semibold text-sm text-center bg-[#008b39]">
                        {translateMonth(sheetName, language)}
                      </th>
                    </tr>
                    <tr className='hidden xl:contents'>
                      <th className="bg-gray-200 text-left !border-b-gray-800  !px-4 !py-2 !text-xs uppercase tracking-wider font-semibold text-gray-800 hidden xl:block">{t.tableHeaders.number}</th>
                      <th className="bg-gray-200 text-left !border-b-gray-800  !px-4 !py-2 !text-xs uppercase tracking-wider font-semibold text-gray-800">{t.tableHeaders.route}</th>
                      <th className="bg-gray-200 text-left !border-b-gray-800  !px-4 !py-2 !text-xs uppercase tracking-wider font-semibold text-gray-800">{t.tableHeaders.date}</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {sheetsData[sheetName]?.rows?.length > 0 ? (
                      sheetsData[sheetName].rows.map((row) => (
                        <tr key={row.id}>
                          <td className="whitespace-nowrap px-4 py-1.5 !text-xs text-gray-500 hidden xl:block">{row.nro}</td>
                          <td className="whitespace-nowrap px-4 py-1.5 !text-xs font-medium text-gray-500">
                            {translateRoute(row.ruta)}
                          </td>
                          <td className="whitespace-nowrap px-4 py-1.5 !text-xs text-gray-500">
                            {translateMonthInDate(row.fecha, language)}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr key={`empty-${sheetName}`}>
                        <td colSpan="3" className="px-4 py-3 text-sm text-center text-gray-500">
                          {t.noDepartures}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            ))
          )}
        </div>
      </div>
    </section>
  );
};

export default App;